<template>
  <div>
    <div class="content" ref="content" id="pdfDom">
      <el-button @click="down" class="downBtn" v-if="$route.path !== '/mobile/statistics'">下载统计报告</el-button>

      <el-tabs class="tabs" v-model="activeName" @tab-click="setTab">



        <el-tab-pane v-for="item in list.subjects" :key="item.id" :label="item.pageTitle" :name="item.pageTitle">
          <el-table :data="rateTable" border style="width: 100%;margin-bottom:30px">
            <el-table-column prop="name" label="姓名" align="center">
            </el-table-column>
            <el-table-column prop="raterNum" label="应评价人" align="center">
            </el-table-column>
            <el-table-column prop="realityRaterNum" label="实际评价人" align="center">
            </el-table-column>
          </el-table>
          <div class="firstTop pixel">
            {{ title }} <br />填写率：{{ fillRate }} &emsp; 填写量：{{
              fillQty
            }}人
          </div>
          <div class="firstNum">
            <span @click="setPercentage">百分比</span> &nbsp; | &nbsp;
            <span @click="setNumber">数值</span>
          </div>
          <div>
            <el-table :data="oneTable" class="table" border :header-cell-style="{ 'text-align': 'center' }"
              v-if="value === '百分比'">
              <el-table-column prop="name" label="子问题"> </el-table-column>
              <el-table-column v-for="(item, ind) in titleTable" :key="ind" :label="item">
                <template slot-scope="{ row }">
                  {{ row.rateList[ind] }}%
                </template>
              </el-table-column>
            </el-table>
            <el-table :data="oneTable" class="table" border :header-cell-style="{ 'text-align': 'center' }"
              v-if="value === '数值'">
              <el-table-column prop="name" label="子问题"> </el-table-column>
              <el-table-column v-for="(item, ind) in titleTable" :key="item" :label="item">
                <template slot-scope="{ row }">
                  {{ row.qtyList[ind] }}
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="firstCharts">
            <el-tabs v-model="tabPosition" type="card">
              <el-tab-pane label="柱状图" name="first">
                <div id="main" style="height: 400px; width: 100%"></div>
              </el-tab-pane>
              <el-tab-pane label="条形图" name="second" class="nbnbnb">
                <div id="second" style="height: 400px; width: 82vw"></div>
              </el-tab-pane>
              <el-tab-pane label="折线图" name="third">
                <div id="third" style="height: 400px; width: 82vw"></div>
              </el-tab-pane>
            </el-tabs>
          </div>
          <Result :list="list" :ind="ind"></Result>
          <Proportion :list="list" :ind="ind"></Proportion>
          <Compare :list="list" :ind="ind"></Compare>
          <div class="score pixel">维度题目得分</div>
          <div class="scoreTable">
            <el-table :data="fourTable" class="table" border :header-cell-style="{ 'text-align': 'center' }">
              <el-table-column prop="name" label="题目"> </el-table-column>
              <el-table-column prop="score" label="题目得分"> </el-table-column>
              <el-table-column prop="overall" label="维度得分">
              </el-table-column>
            </el-table>
          </div>
          <div class="con" v-for="(item, ind) in tableList" :key="ind">
            <div class="score pixel">{{ item.title }}</div>
            <div class="scoreTable">
              <el-table :data="item.texts" class="table" border :header-cell-style="{ 'text-align': 'center' }">
                <el-table-column prop="username" label="名称">
                </el-table-column>
                <el-table-column prop="text" label="内容"> </el-table-column>
                <el-table-column prop="replyTime" label="回复时间">
                </el-table-column>
              </el-table>
            </div>
          </div>

        </el-tab-pane>
        <!-- <el-tab-pane label="第二页" name="second">配置管理</el-tab-pane>
        <el-tab-pane label="第三页" name="third">角色管理</el-tab-pane> -->
      </el-tabs>

    </div>
  </div>
</template>

<script>
import Result from "../report/component/result.vue";
import Proportion from "../report/component/proportion.vue";
import Compare from "../report/component/compare.vue";
import html2canvas from "html2canvas";
import JsPDF from "jspdf";
export default {
  name: "report",
  props: ["conList", "tableList", 'rateTable'],
  components: {
    Result,
    Proportion,
    Compare,
  },
  data() {
    return {
      activeName: "第一页",
      tabPosition: "first",
      list: {},
      titleTable: [],
      oneTable: [],
      fourTable: [],
      value: "百分比",
      fillRate: "",
      fillQty: "",
      title: "",
      ind: "0",
    };
  },
  mounted() {
    setTimeout(() => {
      this.getList();
    }, 1000);
    // console.log(this.$route);
  },

  watch: {
    conList() {
      this.getList();
    },
  },
  methods: {
    down() {
      // 当下载pdf时，若不在页面顶部会造成PDF样式不对,所以先回到页面顶部再下载
      // console.log(11111222);
      let top = document.getElementById("pdfDom");
      if (top != null) {
        top.scrollIntoView();
        top = null;
      }
      html2canvas(document.querySelector("#pdfDom"), {
        allowTaint: true,
      }).then(function (canvas) {
        // 获取canvas画布的宽高
        let contentWidth = canvas.width;
        let contentHeight = canvas.height;
        // 一页pdf显示html页面生成的canvas高度;
        let pageHeight = (contentWidth / 841.89) * 592.28;
        // 未生成pdf的html页面高度
        let leftHeight = contentHeight;
        // 页面偏移
        let position = 0;
        // html页面生成的canvas在pdf中图片的宽高（本例为：横向a4纸[841.89,592.28]，纵向需调换尺寸）
        let imgWidth = 841.89;
        let imgHeight = (841.89 / contentWidth) * contentHeight;
        let pageData = canvas.toDataURL("image/jpeg", 1.0);
        let PDF = new JsPDF("l", "pt", "a4");
        // 两个高度需要区分: 一个是html页面的实际高度，和生成pdf的页面高度
        // 当内容未超过pdf一页显示的范围，无需分页
        if (leftHeight < pageHeight) {
          PDF.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
        } else {
          while (leftHeight > 0) {
            PDF.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
            leftHeight -= pageHeight;
            position -= 592.28;
            // 避免添加空白页
            if (leftHeight > 0) {
              PDF.addPage();
            }
          }
        }
        PDF.save("统计报告" + ".pdf");
      });
    },
    setTab(index) {
      this.ind = index.index;
      this.getList();
    },
    setPercentage() {
      this.value = "百分比";
    },
    setNumber() {
      this.value = "数值";
    },
    drawChart() {
      // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
      let myChart = this.$echarts.init(document.getElementById("main"));
      let name = [];
      let colorArr = ["#E40714", "#FD9205", "#FED200", "#BEEA02", "#008755"];
      let dataArr = [];
      let series = [];
      this.oneTable.forEach((item, ind) => {
        const length = ind.toString().length;
        name.push(item.name.slice(0, length));
        dataArr = [...dataArr, item.qtyMap];
      });
      for (let i = 0; i < this.titleTable.length; i++) {
        let data = dataArr.map((val) => {
          return val[i];
        });
        series.push({
          name: this.titleTable[i],
          type: "bar",
          color: colorArr[i],
          data: data,
        });
      }

      let option = {
        tooltip: {},
        legend: {
          data: this.titleTable,
        },
        xAxis: {
          data: name,
        },
        yAxis: {},
        series: series,
      };

      myChart.setOption(option);
    },
    secondChart() {
      // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
      let myChart = this.$echarts.init(document.getElementById("second"));
      let name = [];
      let colorArr = ["#E40714", "#FD9205", "#FED200", "#BEEA02", "#008755"];
      let dataArr = [];
      let series = [];
      this.oneTable.forEach((item, ind) => {
        const length = ind.toString().length;
        name.push(item.name.slice(0, length));
        dataArr = [...dataArr, item.qtyMap];
      });
      for (let i = 0; i < this.titleTable.length; i++) {
        let data = dataArr.map((val) => {
          return val[i];
        });
        series.push({
          name: this.titleTable[i],
          type: "bar",
          color: colorArr[i],
          data: data,
        });
      }

      let option = {
        tooltip: {},
        legend: {
          data: this.titleTable,
        },
        xAxis: {},
        yAxis: { data: name },
        series: series,
      };

      myChart.setOption(option);
    },
    thirdChart() {
      // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
      let myChart = this.$echarts.init(document.getElementById("third"));
      let name = [];
      let colorArr = ["#E40714", "#FD9205", "#FED200", "#BEEA02", "#008755"];
      let dataArr = [];
      let series = [];
      this.oneTable.forEach((item, ind) => {
        const length = ind.toString().length;
        name.push(item.name.slice(0, length));
        dataArr = [...dataArr, item.qtyMap];
      });
      for (let i = 0; i < this.titleTable.length; i++) {
        let data = dataArr.map((val) => {
          return val[i];
        });
        series.push({
          name: this.titleTable[i],
          type: "line",
          color: colorArr[i],
          data: data,
        });
      }

      let option = {
        tooltip: {},
        legend: {
          data: this.titleTable,
        },
        xAxis: { data: name },
        yAxis: {},
        series: series,
      };

      myChart.setOption(option);
    },
    getList() {
      this.list = this.conList;
      this.list.subjects[this.ind].oneList.forEach((item) => {
        item.qtyMap = Object.values(item.qtyMap);
        item.rateMap = Object.values(item.rateMap);
      });
      this.oneTable = this.list.subjects[this.ind].oneList;
      this.fourTable = this.list.subjects[this.ind].fourList;
      this.titleTable = this.list.subjects[this.ind].titleList;
      this.fillRate = this.list.subjects[this.ind].fillRate;
      this.fillQty = this.list.subjects[this.ind].fillQty;
      this.title = this.list.subjects[this.ind].title;
      setTimeout(() => {
        this.drawChart();
        this.secondChart();
        this.thirdChart();
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.pixel {
  font-size: 10px;
}

.content {
  margin-top: 10px;
  background: #fff;
  padding: 10px 15px 15px 15px;
  position: relative;

  .downBtn {
    // position: absolute;
    // display: block;
    // right: 10px;
    // top: 8px;
    float: right;
  }

  .tabs {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    ::v-deep .el-tabs__nav {
      width: 100%;
      display: flex;
      justify-content: flex-start;
    }

    ::v-deep .el-tabs__active-bar {
      background: none;
    }

    ::v-deep .el-tabs__nav-wrap::after {
      background: none;
    }

    ::v-deep .el-tabs__item {
      font-size: 16px;
    }
  }

  .score {
    background: #f5fff7;
    border-left: #008755 2px solid;
    padding: 10px 15px;
  }

  .table {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;

    ::v-deep .cell {
      display: flex;
      justify-content: center;
    }
  }
}

.firstTop {
  // line-height: 26px;
  color: #3d3d3d;

  &.pixel {
    font-size: 10px;
  }
}

.firstNum {
  font-size: 10px;
  color: #008755;
  display: flex;
  justify-content: flex-end;

  span {
    cursor: pointer;
  }
}

.table {
  width: 100%;
  margin-top: 15px;

  ::v-deep .cell {
    display: flex;
    justify-content: center;
  }

  ::v-deep .el-table_1_column_1 {
    display: flex;
    justify-content: flex-start;
  }
}

.firstCharts {
  margin-top: 15px;

  ::v-deep .el-tabs__nav {
    width: 270px !important;
  }

  ::v-deep .el-tabs__header {
    width: 270px !important;
  }

  ::v-deep .el-tabs__item.is-active {
    background: #008755;
    color: #fff;
  }
}

.score {
  background: #f5fff7;
  border-left: #008755 2px solid;
  padding: 10px 15px;
}
</style>
