<template>
  <div>
    <div class="result pixel">综合结果</div>
    <div class="resultTable">
      <el-table
        :data="list.subjects[ind].twoList"
        class="table"
        border
        :header-cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column
          prop="name"
          label="维度"
        > </el-table-column>
        <el-table-column
          prop="score"
          label="维度得分"
        > </el-table-column>
        <el-table-column
          prop="overall"
          label="总体满意度"
        > </el-table-column>
      </el-table>
    </div>
    <div class="resultCharts">
      <div
        id="resultCharts"
        style="height: 400px; width: 100%"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "result",
  props: ["list", 'ind'],
  data() {
    return {};
  },
  mounted() {
    this.getList();
  },
  methods: {
    resultChart() {
      // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
      let myChart = this.$echarts.init(document.getElementById("resultCharts"));
      // 指定图表的配置项和数据
      let name = [];
      let score = []
      let overall = []
      this.list.subjects[this.ind].twoList.forEach((item) => {
        name.push(item.name);
        score.push(item.score)
        overall.push(item.overall)
      });
      let newArr = []
      name.forEach((val) => {
        newArr = [...newArr, { name: val }]
      })
      let option = {
        tooltip: {},
        legend: {
          left: 10,
          data: ["维度得分", "总体满意度"],
        },
        radar: {
          indicator: newArr
        },
        series: [
          {
            type: "radar",
            data: [
              {
                color: "#FD9205",
                value: score,
                name: "维度得分",
              },
              {
                color: "#018755",
                value: overall,
                name: "总体满意度",
              },
            ],
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    getList() {
      setTimeout(() => {
        this.resultChart();
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  width: 100%;
  margin-top: 15px;
  ::v-deep .cell {
    display: flex;
    justify-content: center;
  }
}
.result {
  background: #f5fff7;
  border-left: #008755 2px solid;
  padding: 10px 15px;
  margin-bottom: 15px;

  &.pixel {
    font-size: 10px;
  }
}
.resultCharts,
.proportionCharts,
.compareCharts {
  margin-top: 15px;
}
</style>
