<template>
  <div class="ctrl-compare">
    <div class="compare pixel">个人纬度评价</div>
    <div class="excelButton">
      <el-button @click="exportAllExcel">导出</el-button>
    </div>
    <div class="compareTable">
      <el-table
        :data="compareContent"
        class="table"
        border
        :header-cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column prop="nickname" label="姓名"> </el-table-column>
        <!-- 内部问卷显示,外部问卷不显示 -->
        <el-table-column
          prop="target"
          label="目标被评价次数"
          v-if="$route.query.pos == 1"
        >
        </el-table-column>
        <el-table-column prop="reality" label="实际被评价次数"> </el-table-column>
        <el-table-column v-for="(item, ind) in dimensionList[0]" :key="ind" :label="item.name">
          <template slot-scope="{ row }"> {{ row.dimension[ind].score }} </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- <div class="compareCharts">
      <div
        id="compareCharts"
        style="height: 400px; width: 100%"
      ></div>
    </div> -->
    <!-- 导出弹窗 -->
    <el-dialog
      title="导出"
      :visible="dialogExportVisible"
      :modal-append-to-body="false"
      width="30%"
      @close="dialogExportVisible = false"
    >
      <!-- 导出 明细及全部的excel表格 -->
      <div class="dialogExport">
        <div
          class="exportItem"
          @click="excelDownload(item.type)"
          v-for="item in exportExcelInfo"
          :key="item.type"
        >
        <iconExcel></iconExcel>
          <div class="exportText">{{ item.alt }}</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import apis from "@/apis";
import { downloadExcel } from "@/utils/tools";
import iconExcel from '@/views/questionnaire/svg/icon-excel'
export default {
  name: "compare",
  props: ["list", "ind"],
  components:{iconExcel},
  data() {
    return {
      compareContent: [],
      dialogExportVisible: false,
      numberIndex: 0,
      exportExcelInfo: [
        {
           iconExcel,
          alt: "导出明细",
          type: 1,
        },
        {
           iconExcel,
          alt: "导出统计",
          type: 2,
        },
        {
          iconExcel,
          alt:'导出评价',
          type:3,
        }
      ],
      columns: [
        { prop: "name", label: "姓名" },
        { prop: "age", label: "年龄" },
        { prop: "gender", label: "性别" },
      ],
    };
  },
  mounted() {
    // this.getList();
    this.questionnaireToRaterScore();
  },
  methods: {
    async excelDownload(type) {
      //导出明细
      if (type == 1) {
        let res = await apis.questionnaireDownExcelDetail({ id: this.$route.query.id });
        // console.log(res);
        downloadExcel(res, "个人纬度评价明细");
      } else if(type == 2) {
      //导出统计
        let res = await apis.questionnaireDownExcel({ id: this.$route.query.id });
        downloadExcel(res, "个人纬度评价统计");
      } else if(type == 3){
        let res = await apis.downExcelRateUser({id: this.$route.query.id})
        downloadExcel(res,'实际评价情况统计');
      }
    },
    exportAllExcel() {
      this.dialogExportVisible = true;
    },
    //个人纬度问卷评价信息
    async questionnaireToRaterScore() {
      try {
        let res = await apis.questionnaireToRaterScore({ id: this.$route.query.id });
        this.compareContent = res.data;
      } catch (error) {
        console.log(error);
      }
    },
    // compareCharts() {
    //   // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
    //   let myChart = this.$echarts.init(
    //     document.getElementById("compareCharts")
    //   );
    //   let name = [];
    //   let overall = [];
    //   let score = []
    //   this.list.subjects[this.ind].threeList.forEach((item) => {
    //     name.push(item.name);
    //     overall.push(item.overall)
    //     score.push(item.score)
    //   });
    //   // 指定图表的配置项和数据
    //   let option = {
    //     tooltip: {},
    //     legend: {
    //       data: ["满意度得分", "总体得分"],
    //     },
    //     xAxis: {
    //       data: name,
    //     },
    //     yAxis: {},
    //     series: [
    //       {
    //         name: "满意度得分",
    //         type: "bar",
    //         color: "#E40714",
    //         data: overall,
    //       },
    //       {
    //         name: "总体得分",
    //         type: "line",
    //         color: "#FD9205",
    //         data: score,
    //       },
    //     ],
    //   };
    //   // 使用刚指定的配置项和数据显示图表。
    //   myChart.setOption(option);
    // },
    // getList() {
    //   setTimeout(() => {
    //     this.compareCharts()
    //   }, 300)
    // }
  },
  computed: {
    dimensionList() {
      return this.compareContent.map((item) => {
        return item.dimension;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ctrl-compare {
  padding: 19px 0px;
  .table {
    width: 100%;
    margin-top: 15px;
    ::v-deep .cell {
      display: flex;
      justify-content: center;
    }
  }
  .compare {
    background: #f5fff7;
    border-left: #008755 2px solid;
    padding: 10px 15px;
    margin-bottom: 15px;

    &.pixel {
      font-size: 10px;
    }
  }
  .excelButton {
    float: right;
    // padding: 10px 15px ;
    margin-bottom: 20px;
  }

  .dialogExport {
    display: flex;
    justify-content: space-around;
    .exportItem {
      display: flex;
      flex-direction: column;
      .exportImg {
        cursor: pointer;
      }
      .exportText {
        margin-top: 10px;
        text-align: center;
      }
    }
  }

  // .compareCharts {
  //   margin-top: 15px;
  // }
}
</style>
