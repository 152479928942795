<template>
  <div>
    <div class="proportion pixel">各维度选项占比</div>
    <div class="proportionTable">
      <el-table
        :data="list.subjects[ind].twoList"
        class="table"
        border
        :header-cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column
          prop="name"
          label="维度"
        > </el-table-column>
        <el-table-column
          prop="rate"
          label="满意率"
        > </el-table-column>
        <el-table-column
          v-for="(item, ind) in titleTable"
          :key="ind"
          :label="item"
        >
          <template slot-scope="{ row }"> {{ row.qtyList[ind] }} </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="proportionCharts">
      <div
        id="proportionCharts"
        style="height: 400px; width: 100%"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "proportion",
  props: ["list", 'ind'],
  data() {
    return {
      titleTable: []
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    proportionCharts() {
      // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
      let myChart = this.$echarts.init(
        document.getElementById("proportionCharts")
      );
      let name = [];
      let colorArr = ["#E40714", "#FD9205", "#FED200", "#BEEA02", "#008755"];
      let dataArr = [];
      let series = [];
      this.list.subjects[this.ind].twoList.forEach((item) => {
        name.push(item.name);
        dataArr = [...dataArr, item.qtyMap];
      });
      for (let i = 0; i < this.titleTable.length; i++) {
        let data = dataArr.map((val) => {
          return val[i];
        });
        series.push({
          name: this.titleTable[i],
          type: "bar",
          color: colorArr[i],
          data: data,
        });
      }

      let option = {
        tooltip: {},
        legend: {
          data: this.titleTable,
        },
        xAxis: {
          data: name,
        },
        yAxis: {},
        series: series,
      };

      myChart.setOption(option);
    },
    getList() {
      setTimeout(() => {
        this.list.subjects[this.ind].twoList.forEach((item) => {
          item.qtyMap = Object.values(item.qtyMap);
        });
        this.titleTable = this.list.subjects[this.ind].titleList;
        this.proportionCharts();
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  width: 100%;
  margin-top: 15px;
  ::v-deep .cell {
    display: flex;
    justify-content: center;
  }
}
.proportion {
  background: #f5fff7;
  border-left: #008755 2px solid;
  padding: 10px 15px;
  margin-bottom: 15px;

  &.pixel {
    font-size: 10px;
  }
}
.proportionCharts {
  margin-top: 15px;
}
</style>
