<template>
   <svg width="55px" height="55px" viewBox="0 0 17 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>excel</title>
    <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="切图" transform="translate(-499.000000, -1717.000000)">
            <g id="excel" transform="translate(499.000000, 1717.000000)">
                <path d="M1,0 L10.984,0 L16,5 L16,17 C16,17.5522847 15.5522847,18 15,18 L1,18 C0.44771525,18 0,17.5522847 0,17 L0,1 C0,0.44771525 0.44771525,0 1,0 Z" id="路径_3738" fill="#03A83C" fill-rule="nonzero"></path>
                <path d="M16.009,5 L12,5 C11.4477153,5 11,4.55228475 11,4 L11,0 L16.009,5 Z" id="路径_3739" fill="#60D544" fill-rule="nonzero"></path>
                <g id="组_4069" transform="translate(4.000000, 6.000000)" stroke="#FFFFFF" stroke-linecap="round">
                    <line x1="3.49749885" y1="-0.0354988488" x2="3.49749885" y2="7.02850115" id="直线_148" transform="translate(3.497499, 3.496501) rotate(45.000000) translate(-3.497499, -3.496501) "></line>
                    <line x1="2.14869497" y1="0.523194966" x2="2.14869497" y2="3.77219497" id="直线_149" transform="translate(2.148695, 2.147695) rotate(-45.000000) translate(-2.148695, -2.147695) "></line>
                    <line x1="4.677" y1="4.676" x2="6" y2="5.999" id="直线_154" opacity="0.6"></line>
                </g>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
export default {
  props: ['active'],
  computed: {
    fill() {
      return this.active ? '#008755' : '#3D3D3D';
    }
  }
}
</script>

<style scoped>

</style>